<template>
    <div>
        <b-container class="bv-example-row" id="container">
            <b-row id="titleBox">
                <b-col>
                    <h2>Skills</h2>
                </b-col>
            </b-row>
            <b-row class="p20">
                <b-col>
                    <b-button disabled v-for="(skill, i) in skills" :key="i" class="button">{{skill.skill}}</b-button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
export default {
    data () {
        return{
            skills:[
                {skill:"HTML"},
                {skill:"CSS"},
                {skill:"Javascript"},
                {skill: "Bootstrap"},
                {skill: "jQuery"},
                {skill: "Adobe Photoshop"},
                {skill: "Vue.JS"},
                {skill: "React"},
                {skill: "Semantic UI"},
                {skill: "Express.JS"},
                {skill: "MongoDB"},
                {skill: "GitHub"},
                {skill: "Node"},
                {skill: "Netlify"},
                {skill: "API"},
                {skill: "Atom"},
                {skill: "Sublime2"},
                {skill: "Visual Studio Code"},
                {skill: "Blender"},
                {skill: "Google Maps API"},
                
            ]
        }
    }
}
</script>
<style scoped>
    
h2{
    color: white;
    font-weight: bold;
    padding: 1rem;
    font-size:4rem;
    margin: 0;
}
#titleBox{
    background-color: #a3a3a3;
}
.skillImg{
    width:150px;
}
.p20{
     padding:2rem 0 0;
}
button{
    margin:.5rem;
    font-size: 1.5rem;
}
</style>