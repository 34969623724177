<template>
    <div>
        <b-img src="/images/banner.png" />
       
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.contact{
    color:white;
}
</style>