<template>
    <div>
        <b-container>
            <b-row id="titleBox">
                <b-col>
                    <h2>Education</h2>
                </b-col>
            </b-row>
             <b-row class="p20" align-h="center">
                 
                <b-col class="mb20" md="4" sm="10" v-for="(item, i) in education" :key="i">
                    <b-card class="mh">
                        <a :href="item.url"><b-img :src="item.imgUrl" /></a>
                        <b-card-text>
                            <hr/>
                            <h5>{{item.program}}</h5>
                            <p>{{item.desc}}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
export default {
    data () {
        return{
            education:[
                {place:"Washington University in St. Louis", imgUrl:"/images/washu-nice.png", url:"https://samfoxschool.wustl.edu/", program:"BFA Printmaking", desc:"Got my BFA in Printmaking. Focused on Woodcuts and Digital Prints. Elective Sculpture including welding, moldmaking and blacksmithing. "},
                {place:"Gnomon School of Visual Effects", imgUrl:"/images/gnomon-nice.png", url:"https://www.thegnomonworkshop.com/", program:"Entertainment Design", desc:"Studied a Year of Entertainment Design. Studied Photoshop, Maya, Digital composition, enviorment, vehicle and creature desgin.  "},
                {place:"Udacity", imgUrl:"/images/udacity-nice.png", url:"https://www.udacity.com/", program:"Front-End Nanodegree", desc:"Completed the Nanodegree program. Included Web Design Fundamentals, Javascript, HTML5 Canvas, Web Optimization, AJAX and 3rd Party APIs, and Test Focused Development."},
            ]
        }
    }
}
</script>
<style scoped>
h2{
    font-size: 4rem;
}
#titleBox{
    background-color: #a3a3a3;
}
.p20{
    padding:2rem 0;
}
.mb20{
    margin-bottom: 20px;
}
h5{
    font-weight: bold;
}
.mh{
    min-height: 435px;
}
h2{
    color: white;
    font-weight: bold;
    padding: 1rem;
    margin: 0;
}

</style>