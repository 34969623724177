<template>
  <div id="app">
    <jumboHeader/>
    <educationBox/>
     <skillsBox />
    <experienceBox/>
    <!--graphicDesign id="bottom"/-->
   
  </div>
</template>

<script>
import jumboHeader from './components/jumbo-header.vue'
import educationBox from './components/education-box.vue'
import experienceBox from './components/experience-box.vue'
//import graphicDesign from './components/graphic-design.vue'
import skillsBox from './components/skills-box.vue'

export default {
  name: 'App',
  components: {
    jumboHeader,
    educationBox,
    experienceBox,
    //graphicDesign,
    skillsBox
    
  }
}
</script>

<style>
#app {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bolder;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}

</style>
