<template>
    <div>
        <b-container id="experience-box">
            <b-row id="titleBox">
                <b-col>
                    <h2>Websites</h2>
                </b-col>
            </b-row>
            <b-row class="p20" align-h="center">
                <b-col md="6" sm="12" class="mb20">
                    <b-card>
                        <b-img alt="Longhofer Peru Adventure" :src="require('../assets/images/pla.png')" />
                            <h4>Longhofer Peru Adventure</h4>
                            <hr/>
                                <p>Produced a brochure website for an adventure edutainment trip to the Amazon. Uses an Accuweather API call to get local temperatures and conditions to put some of the Peruvian destinations on display.  Built with Vue.JS, used the Material theme for the floating action button, and hosted on Netlify. </p>
                             <b-card-text><a href="https://longhoferperuadventure.com/">Click To visit</a></b-card-text>
                             
                            
                        
                    </b-card>
                </b-col>
                
                <b-col md="6" sm="12" class="mb20">
                    <b-card>
                        <b-img alt="Tempe Ostergren-Elsberry Portfolio" src="/images/tempeportfolio.png" />
                            <h4>Tempe Ostergren-Elsberry Portfolio Site</h4>
                            <hr/>
                            <p>Produced a portfolio website for a former Dancer moving into Teaching. Built with React and Bootstrap. We went ahead and embedded several YouTube clips to demonstrate projects she had worked on. Finally settled on a design that looked great and the client was happy with.  Featured a React Lightbox and hosted on Netlify.</p>
                             <b-card-text><a href="https://tempeostergrenelsberry.com/">Click To visit</a></b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
       

    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
#experience-box{
    padding: 2rem ;
}

h2{
    font-size: 4rem;
}
#titleBox{
    background-color: #a3a3a3;
}
.p20{
     padding:2rem 0;
}
.b-img h4{
    min-height: 50px;
}

.mb20{
    margin-bottom: 20px;
}

.minh{
    min-height: 20rem;
}
h4{
    font-weight: bold;
    padding-top:1rem;
}
h2{
    color: white;
    font-weight: bold;
    padding: 1rem;
    margin: 0;
}
</style>